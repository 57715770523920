





























import { Component, Emit, Vue, Prop, Watch } from 'vue-property-decorator';
import { WecomVideoServices } from '@/services/wecom-video-service';
import { Inject } from '@cds/common';
import { map, find, forEach, isEmpty, get, split, toNumber, cloneDeep, pull, pullAllBy } from 'lodash';

@Component({})
export default class UserGroupSelector extends Vue {
  [x: string]: any;
  public groupTitle: String = '选择用户组';
  public groupVisible: boolean = false;
  public groupDataList: any[] = [];
  public groupValue: Array<any> = [];
  public groups: any[] = []
  public groupQueryParams: any = { //查询参
    userGroupName: '',
    pageNo: 1,
    pageSize: 100000,
    total: 0
  };
  @Prop({ type: Array, default: [] }) value !: any[]
  @Prop({ type: Boolean, default: false }) disabled !: Boolean
  @Prop({ type: Array, default: [] }) unauthGroups !: any[]
  @Watch('value')
  private setValue(newVal: any[]) {
    this.groupValue = newVal;
    const unauthGroups = map(this.$props.unauthGroups, (item) => {
      return {
        ...item,
        unauth: true,
      }
    })
    if (isEmpty([...this.groupDataList, ...unauthGroups])) return
    this.groups = map(newVal, (value) => {
      return find([...this.groupDataList, ...unauthGroups], { key: value })
    })

  }
  @Inject(WecomVideoServices) private wecomVideoServices!: WecomVideoServices;
  public async created() {
    await this.getGroupList();
    if (this.$props.value) {
      const unauthGroups = map(this.$props.unauthGroups, (item) => {
        return {
          ...item,
          unauth: true,
        }
      })
      this.groupValue = [...this.$props.value];
      this.groups = map(this.groupValue, (value) => {
        return find([...this.groupDataList, ...unauthGroups], { key: value });
      })
    }
  }
  public goUserGroup() {
    const origin = window.location.origin;
    window.open(`${origin}/usergroup`)
  }
  public groupFilterMethod(query: any, item: any) {
    return item.label.indexOf(query) > -1;
  }
  public async refresh() {
    const loading = this.$loading({
      text: '刷新中',
    })
    await this.getGroupList()
    loading.close();
  }
  public handleClose(item: any) {
    if (this.$props.disabled) return;
    const groups = cloneDeep(this.groups);
    pullAllBy(groups, [item], 'label');
    this.groups = groups;
    const groupValues = cloneDeep(this.groupValue);
    const pullGroupId = item.unauth ? item.isCustomer ? `custom_${item.groupId}` : `${item.groupId}` : `${item.groupId}`
    pull(groupValues, pullGroupId);
    this.groupValue = groupValues;
    this.$forceUpdate();
    this.groupChange(groupValues);
  }
  @Emit('input')
  public onChange(value) {
    return value
  }

  @Emit('change')
  public groupChange(value) {
    let groupIds: any[] = [];
    let customGroupIds: any[] = [];

    const newGroups = [];
    forEach(value, (val) => {
      const target = find([...this.groupDataList, ...this.$props.unauthGroups], { key: val });
      if (!target) return
      newGroups.push(val);
      if (target.isCustomer) {
        customGroupIds.push(split(val, '_')[1]);
      } else {
        groupIds.push(val);
      }
    })
    groupIds = map(groupIds, toNumber);
    customGroupIds = map(customGroupIds, toNumber);
    this.onChange(newGroups);
    return {
      groupIds,
      customGroupIds
    }
  }

  public groupSubmit() {
    this.groupVisible = false;
    this.groups = map(this.groupValue, (value) => {
      return find([...this.groupDataList, ...this.$props.unauthGroups], { key: value })
    })

    this.groupChange(this.groupValue);
  }

  public groupChoose() {
    if (this.$props.disabled) return;
    this.groupVisible = true
  }
  public async getGroupList(): Promise<void> {
    const res = await this.wecomVideoServices.getAllUserGroups(this.groupQueryParams);
    this.groupDataList = res.records.map((item: any) => {
      const id = item.isCustomer ? `custom_${item.userGroupId}` : `${item.userGroupId}`;
      return {
        ...item,
        key: id,
        value: id,
        editable: true,
        label: `${item.userGroupName}${item.isCustomer ? '(自定义分组）' : ''}`,
        groupId: id
      };
    });
    const unauthGroups = map(this.$props.unauthGroups, (item) => {
      return {
        ...item,
        unauth: true,
      }
    })
    this.groups = map(this.groupValue, (value) => {
      return find([...this.groupDataList, ...unauthGroups], { key: value })
    })
    this.groupQueryParams.total = res.total;
  }
}
